import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './index.module.css'

export function SectionTitle(props) {
  const { children, ...rest } = props

  return (
    <h1 className={styles.sectionTitle} {...rest}>
      {children}
    </h1>
  )
}
SectionTitle.propTypes = { children: PropTypes.node.isRequired }
