import React from 'react'

import * as styles from './index.module.css'

export function Table(props) {
  return <table className={styles.table} {...props} />
}

Table.Body = (props) => <tbody {...props} />
Table.Body.displayName = 'Table.Body'

Table.Head = (props) => <thead {...props} />
Table.Head.displayName = 'Table.Head'

Table.Row = (props) => <tr {...props} />
Table.Row.displayName = 'Table.Row'

Table.Header = (props) => <th {...props} />
Table.Header.displayName = 'Table.Header'

Table.Cell = (props) => <td {...props} />
Table.Cell.displayName = 'Table.Cell'
