import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { gql, useQuery } from '@apollo/client'

import { clearLocalStorageItem } from '../../apollo/util'

import { MainLayout } from '../../layouts/main'
import { ContactSidebarCard } from '../../components/contact-sidebar-card'

import { Login } from '../../components/login'
import { Logout } from '../../components/logout'
import { SectionTitle } from '../../components/section-title'
import { Table } from '../../components/table'

import * as styles from './index.module.css'

const PRICE_CODES = [
  { code: 'BO', desc: 'Base only' },
  { code: 'GT60', desc: 'Metal base with glass top 60" diameter' },
  { code: 'WT60', desc: 'Metal base with wood top 60" diameter' },
  { code: 'MAH', desc: 'Price in premium mahogany wood' },
  { code: 'OAK', desc: 'Price in premium oak wood' },
  { code: 'WAL', desc: 'Price in premium walnut wood' },
  { code: 'SM', desc: 'Small size' },
  { code: 'GSL', desc: 'Upcharge for gold or silver leaf' },
  { code: 'GSLD', desc: 'Upcharge for gold or silver leaf on doors only' },
  { code: 'IR', desc: 'Upcharge for infrared eye' },
  { code: 'LS', desc: 'Upcharge for lazy susan' },
  {
    code: 'DM',
    desc: 'Upcharge for sliding door mechanism / price is per door',
  },
  { code: 'TR', desc: 'Upcharge for trim' },
  { code: 'TT', desc: 'Upcharge for two tone' },
  { code: 'WF', desc: 'Upcharge for weathered finish' },
  { code: 'AM', desc: 'With antique mirror' },
]

const VIEWER = gql`
  query Viewer {
    viewer {
      id
      firstName
      lastName
      roles {
        name
      }
    }
  }
`

export default function LoginPage() {
  const { data, loading, error } = useQuery(VIEWER, {
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    clearLocalStorageItem('apiToken')
    console.log('clearning apiToken')
  }, [])

  const viewerId = data && data.viewer && data.viewer.id

  const isLoggedIn = !!viewerId

  const onLogin = () => {
    window.location.assign(window.location)
  }

  return (
    <MainLayout>
      <SectionTitle>
        {isLoggedIn
          ? 'Prices'
          : 'Login (PRICING NOT AVAILABLE ONLINE AT THIS TIME)'}
      </SectionTitle>
      <Content>
        <Aside>
          {isLoggedIn ? (
            <>
              <h2>Price Basis and Codes</h2>
              <p>
                Old Biscayne creates each piece to order for every order. If
                custom designs are required please, e-mail:
                quotes@oldbiscaynedesigns.com.
              </p>

              <dl>
                <dt>
                  <strong>Wood</strong>
                </dt>
                <dd>
                  Our wood design are all manufactured using Maple unless
                  otherwise specified. All other wood species will carry an
                  upcharge
                </dd>

                <dt>
                  <strong>Upholstery</strong>
                </dt>
                <dd>
                  All upholstered designs are priced with COM. OBD leather and
                  fabric will carry an upcharge.
                </dd>

                <dt>
                  <strong>Finishes</strong>
                </dt>
                <dd>
                  All designs are priced with one standard finish; regardless of
                  how the design is shown on the website. If using wood and
                  iron, the price included one standard wood finish and one
                  standard metal finish.
                </dd>

                <dt>
                  <strong>Hardware</strong>
                </dt>
                <dd>
                  Designs are priced with the hardware pictured unless it is
                  Customer’s own hardware or was discontinued since the picture
                  was posted. Any changes require a custom quote.
                </dd>
              </dl>

              <p>
                Freight Cost May be calculated as 8 to 12% percent of the total
                invoice depending on your location in relation to our factory in
                Georgia.
              </p>
            </>
          ) : (
            <ContactSidebarCard />
          )}
        </Aside>

        <Main>
          {!isLoggedIn ? (
            <>
              <div className={styles.formTitle}>Login</div>
              <Login refetch={onLogin} />
              <p>
                Don't have an account? Click{' '}
                <Link to="/registration">here</Link> to register.
              </p>
            </>
          ) : (
            <>
              <h2>Pricing Codes</h2>

              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header className={styles.codeHeader}>
                      Code
                    </Table.Header>
                    <Table.Header>Description</Table.Header>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  {PRICE_CODES.map((code, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{code.code}</Table.Cell>
                      <Table.Cell>{code.desc}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </Main>
      </Content>
      {isLoggedIn && (
        <div className={styles.logout}>
          <hr className={styles.separator} />

          <p>
            You have successfully logged in as{' '}
            <strong>{data && data.viewer && data.viewer.firstName}</strong>
          </p>
          <p>
            <Logout />
          </p>

          <hr className={styles.separator} />
        </div>
      )}
    </MainLayout>
  )
}

const Aside = (props) => {
  return <aside className={styles.aside} {...props} />
}

const Content = (props) => {
  return <div className={styles.content} {...props} />
}

const Main = (props) => {
  return <div className={styles.main} {...props} />
}
