import cx from 'classnames'
import React from 'react'

import { CompanyLogo } from '../company-logo'

import * as styles from './index.module.css'

export function ContactSidebarCard() {
  return (
    <div className={styles.contactCard}>
      <div className={styles.logo}>
        <CompanyLogo />
      </div>

      <div className={cx('h-card')}>
        <h3 className={cx('p-name', styles.name)}>
          Old Biscayne Designs, Inc.
        </h3>

        <p>
          <span>Mail To:</span>{' '}
          <span className="p-street-address">PO Box 688</span>
          <br />
          <span className="p-locality">Americus</span>
          {', '}
          <span className="p-region">Georgia</span>
          {', '}
          <span className="p-postal-code">31709</span>
        </p>

        <p>
          <span>Corporate Address:</span>{' '}
          <span className="p-street-address">123 Industrial Blvd.</span>
          <br />
          <span className="p-locality">Americus</span>
          {', '}
          <span className="p-region">Georgia</span>
          {', '}
          <span className="p-postal-code">31719</span>
        </p>

        <p>
          <a className={styles.phoneNumber} href="tel:800-397-5397">
            +1 (800) 397-5397
          </a>{' '}
          <span>Toll Free</span>
        </p>

        <p>
          <a className={styles.phoneNumber} href="tel:229-928-4050">
            +1 (229) 928-4050
          </a>{' '}
          <span>Office</span>
        </p>

        <p>
          <a className={styles.phoneNumber} href="tel:229-928-0805">
            +1 (229) 928-0805
          </a>{' '}
          <span>Fax</span>
        </p>

        <p className={styles.website}>
          <a href="www.OldBiscayneDesigns.com">www.OldBiscayneDesigns.com</a>
        </p>
      </div>
    </div>
  )
}
