import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { gql, useMutation } from '@apollo/client'

import * as styles from './index.module.css'

const AUTHENTICATE = gql`
  mutation authenticate($input: AuthenticateInput!) {
    authenticate(input: $input) {
      jwtToken
    }
  }
`

export function Login({ refetch }) {
  const [values, setValues] = useState({ email: '', password: '' })
  const [authenticationFailed, setAuthenticationFailed] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const [authenticate] = useMutation(AUTHENTICATE)

  const setAuth = (name) => {
    return ({ target: { value } }) => {
      setAuthenticationFailed(false)

      setValues((oldValues) => ({ ...oldValues, [name]: value }))
    }
  }

  const onSubmitAuth = async (event) => {
    event.preventDefault()

    try {
      const res = await authenticate({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      })

      const jwt = R.path(['data', 'authenticate', 'jwtToken'], res)
      if (!jwt) throw new Error()

      refetch()
    } catch (e) {
      setAuthenticationFailed(true)
    }
  }

  const onPasswordToggle = (e) => {
    e.preventDefault()

    setShowPassword((prev) => !prev)
  }

  return (
    <form className={styles.login} onSubmit={onSubmitAuth}>
      <div className={`${styles.formGroup}`}>
        <label className={`${styles.formLabel}`} htmlFor="email">
          Email Address
        </label>
        <input
          className={`${styles.formInput} ${styles.email}`}
          type="text"
          id="email"
          name="email"
          value={values.email}
          onChange={setAuth('email')}
        />
      </div>
      <div className={`${styles.formGroup}`}>
        <label className={`${styles.formLabel}`} htmlFor="password">
          Password
        </label>
        <div className={`${styles.formInput}`}>
          {showPassword ? (
            <input
              className={`${styles.passwordInput}`}
              type="text"
              id="password"
              name="password"
              value={values.password}
              onChange={setAuth('password')}
            />
          ) : (
            <input
              className={`${styles.passwordInput}`}
              type="password"
              id="password"
              name="password"
              value={values.password}
              onChange={setAuth('password')}
            />
          )}
          <button
            className={`${styles.passwordToggle}`}
            onClick={onPasswordToggle}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      {authenticationFailed && (
        <div className={styles.authFailed}>* Incorrect email or password</div>
      )}

      <div className={styles.submit}>
        <button type="submit">Submit</button>
      </div>
    </form>
  )
}

Login.propTypes = {
  refetch: PropTypes.func.isRequired,
  defaultLogin: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
}
