import axios from 'axios'
import React, { useState } from 'react'

const url = `${process.env.GATSBY_OLD_API_URL}/logout`

export function Logout() {
  const [isError, setIsError] = useState(false)

  const logout = async (e) => {
    e.preventDefault()

    axios({
      method: 'post',
      url: url,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        localStorage.removeItem('apiToken')
        window.location.assign(window.location)
      })
      .catch(function (response) {
        setIsError(true)
      })
  }

  return (
    <>
      <button onClick={logout}>Logout</button>{' '}
      {isError ? (
        <span>
          There was an error while logging out. Please try again later.
        </span>
      ) : null}
    </>
  )
}
